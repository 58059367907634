import type { ThemeOption } from '$lib/types/theme-option';

export function applyTheme(node: HTMLElement, theme: ThemeOption) {
  const updateTheme = (theme: ThemeOption) => {
    node.setAttribute('data-theme', theme);
  };

  updateTheme(theme);

  return {
    update: updateTheme
  };
}
