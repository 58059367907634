<script lang="ts">
  import '$lib/firebase'; // Initialize Firebase
  import '../app.css';
  import { applyTheme } from '$lib/actions/apply-theme';
  import { theme } from '$lib/stores/theme';
  import { ThemeOption } from '$lib/types/theme-option';
</script>

<div use:applyTheme={$theme} class="font-sans flex flex-col min-h-screen">
  <header class="header flex justify-between items-center p-4">
    <div>
      <a href="/" aria-label="Go to home page" class="text-xl md:text-3xl font-bold text-primary">
        Point Poker
      </a>
    </div>
    <div>
      <label class="flex cursor-pointer gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          ><circle cx="12" cy="12" r="5" /><path
            d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"
          /></svg
        >
        <input
          id="theme-toggle"
          type="checkbox"
          checked={$theme === ThemeOption.DARK}
          on:change={() => theme.toggle()}
          class="toggle theme-controller"
        />
        <span class="sr-only">Toggle Light/Dark theme</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          ><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg
        >
      </label>
    </div>
  </header>

  <slot />

  <footer class="p-4 text-sm">
    <div class="flex flex-col md:flex-row justify-between items-center">
      <p class="mb-2 md:mb-0">
        &copy; 2024 <a
          href="https://www.lewandowski.dev/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Go to developer's portfolio site"
          class="link link-primary">Patrick Lewandowski</a
        >. All rights reserved |
        <a href="/privacy" aria-label="Go to privacy policy" class="link link-primary"> Privacy </a>
      </p>

      <a
        href="/feedback"
        aria-label="Go to feedback page"
        class="flex items-center space-x-2 link link-hover"
      >
        <span>Leave Feedback</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 512 512"
          class="fill-current"
        >
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
          />
        </svg>
      </a>
    </div>
  </footer>
</div>

<div class="toast toast-center">
  <div class="alert alert-error">
    <span></span>
  </div>
</div>
