import { writable } from 'svelte/store';
import { ThemeOption } from '$lib/types/theme-option';
import { safeLocalStorage } from '$lib/utils/safe-local-storage';

// WARNING: There is a hack in the app.html to set the initial theme that needs to be updated if this changes
const THEME_KEY = 'user-preference-theme';

function createThemeStore() {
  // WARNING: There is a hack in the app.html to set the initial theme that needs to be updated if this changes
  let initialValue;
  if (typeof window !== 'undefined') {
    initialValue = safeLocalStorage.getItem(THEME_KEY) as ThemeOption;

    // if no value stored, use the OS preference
    if (!initialValue) {
      initialValue = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? ThemeOption.DARK
        : ThemeOption.LIGHT;
    }

    // data validation
    if (initialValue !== ThemeOption.LIGHT && initialValue !== ThemeOption.DARK) {
      initialValue = ThemeOption.LIGHT;
    }

    // set the initial value in local storage so dark theme users don't always get a flash when entering without toggling
    safeLocalStorage.setItem(THEME_KEY, initialValue);
  }

  const { subscribe, set, update } = writable<ThemeOption>(initialValue);

  return {
    subscribe,
    set: (theme: ThemeOption) => {
      if (typeof window !== 'undefined') {
        safeLocalStorage.setItem(THEME_KEY, theme);
      }
      set(theme);
    },
    toggle: () =>
      update((theme: ThemeOption) => {
        const newTheme = theme === ThemeOption.LIGHT ? ThemeOption.DARK : ThemeOption.LIGHT;
        if (typeof window !== 'undefined') {
          safeLocalStorage.setItem(THEME_KEY, newTheme);
        }
        return newTheme;
      })
  };
}

export const theme = createThemeStore();
